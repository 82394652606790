<template>
    <form 
        v-if="productEdit"
        class="flex flex-col content-start h-full w-full"
        @submit.prevent="updateProduct()">
        
        <div class="flex-none bg-gray-200 flex items-center py-4 px-4 ">
            <button 
                class="button transparent mr-2"
                type="button"
                @click="$emit('close')">
                 
                <i class="fas fa-times " />
            </button>
            
            <h2 class="text-black m-0 flex-grow">
                {{$t('products.editProduct')}}
            </h2>
            
            <div 
                v-if="productEdit.deletedAt"
                class="flex-grow text-left">
                
                {{$t('products.productWasDeleted')}}
                <span>{{ productEdit.deletedAt | moment('from') }}</span>
            </div>
            
            <a 
                v-if="!productEdit.deletedAt"
                :disabled="loading"
                class="button transparent link ml-2"
                :class="{'loading' : loading == 'delete'}"
                @click.prevent="deleteProduct()">
                
                <i class="fas fa-trash mr-2" />
                <span class="">{{$t('general.delete')}}</span>
            </a>
            
            <button 
                :disabled="loading"
                type="submit"
                class="button submit ml-4"
                :class="{'loading' : loading == 'update'}">
                 
                <i class="fas fa-check mr-2" />
                {{$t('general.save')}}
             </button>
        </div>
        
        <div 
            class="
                flex-grow overflow-auto 
                px-8
                pb-16
            ">
        
            <section>
                <InputSingleline 
                    v-model="productEdit.name"
                    :required="true"
                    :label="$t('products.name')"
                    :name="'name'"
                    :showRequiredWarning="showRequiredWarning"
                />
                
                <div class="grid grid-cols-3 gap-4">
                    <InputSingleline 
                        v-model="productEdit.price"
                        :required="true"
                        :label="
                            productEdit.vatPercent && productEdit.vatPercent > 0 ? 
                                $t('products.price')+' '+ $t('products.excludedVat') : 
                                $t('products.price') + ' ' +account.locale.currency
                        "
                        :name="'price'"
                        :placeholder="'9.99'"
                        :showRequiredWarning="showRequiredWarning"
                    />
                    <!-- +' '+account.locale.currency -->
                    <InputSingleline 
                        v-model="productEdit.vatPercent"
                        :name="'vatPercent'"
                        :placeholder="'0'"
                        :label="$t('products.vatRate')"
                    />
                    
                    <div v-if="productEdit.price && productEdit.vatPercent && productEdit.vatPercent > 0">
                        <InputSingleline 
                            :value="( (  (productEdit.price*1 * productEdit.vatPercent*1) / 100) + productEdit.price*1 ).toFixed(2)"
                            :required="true"
                            :label="$t('products.price')+' '+$t('products.includedVat')"
                            :name="'price'"
                            readonly
                            disabled
                        />
                        <!-- +' '+account.locale.currency -->
                    </div>
                </div>
                
                <div class="grid grid-cols-3 gap-4">
                    <InputSingleline 
                        v-model="productEdit.priceCost"
                        :label="$t('products.priceCost')+' '+account.locale.currency"
                        :name="'priceCost'"
                        :placeholder="'9.99'"
                    />
                    
                    <div 
                        v-if="productEdit.price && productEdit.priceCost"
                        class="col-span-2">
                        
                        <InputSingleline 
                            :value="(productEdit.price*1 - productEdit.priceCost*1).toFixed(1) + ' ' + account.locale.currency + ' / '+ ((productEdit.price*1 - productEdit.priceCost*1) / productEdit.price *100).toFixed(1) +'% '"
                            :label="$t('products.contributionMargin')"
                            :name="'contributionMargin'"
                            :placeholder="'5.50'"
                            readonly
                            disabled
                        />
                    </div>
                </div>
                
                
                <InputMultiline 
                    v-model="productEdit.description"
                    :label="$t('products.description')"
                    :name="'description'"
                    :showRequiredWarning="showRequiredWarning"
                />
                
                <InputSingleline 
                    v-model="productEdit.url"
                    :label="$t('products.url')"
                    :name="'url'"
                    :type="'url'"
                    :placeholder="'https://www.example.com/product123'"
                    :showRequiredWarning="showRequiredWarning"
                />
                
                <label class="h-10 flex items-center pb-1">
                    <input 
                        type="checkbox"
                        v-model="productEdit.isDateProduct"
                        class="mr-2"
                    />
                    
                    <div class="mt-2">
                        {{$t('products.isDateProduct')}}
                    </div>
                </label>
            </section>
        
            <section class="mb-8">
                <label>{{$t('products.images')}}</label>
                
                <div
                    v-if="productEdit.images.length"
                    class="grid sm:grid-cols-2 gap-8 mb-8">
                    
                    <div 
                        v-for="image in productEdit.images"
                        :key="image.fileId"
                        class="relative">
                        
                        <img 
                            :src="imageToUrl(image)"
                            alt="Image"
                            class="w-full h-full object-cover bg-gray-200 rounded-lg "
                        />
                        
                        <div class="absolute top-0 right-0 m-4 overflow-hidden flex ">
                            <i 
                                class="fas fa-trash text-sm text-gray-700 bg-white w-8 h-8 flex items-center justify-center mr-2"
                                :title="$t('general.remove')"
                                @click="removeImage(image)"
                            />
                            <i 
                                v-if="productEdit.images.length > 1"
                                class="fas fa-star text-sm text-black-700 bg-white w-8 h-8 flex items-center justify-center"
                                :class="productEdit.cover == image.fileId ? 'bg-cyan text-white' : ''"
                                :title="$t('products.useImageAsCover')"
                                @click="setCover(image)"
                            />
                        </div>
                    </div>
                    
                </div>
                
                <InputUpload
                    :name="'images'"
                    :label="$t('general.takePhotoOrUploadImage')"
                    :showRequiredWarning="showRequiredWarning"
                    :multiple="true"
                    @input="onImageAdded($event)"
                />
            </section>
            
            <section class="mb-8">
                <label>{{$t('general.attachments')}}</label>
                
                <div
                    v-if="productEdit.attachments.length"
                    class="mb-4 -mx-4">
                    
                    <div 
                        v-for="attachment in productEdit.attachments"
                        :key="attachment.fileId"
                        class="mx-4 mb-2 p-4 bg-gray-100 flex items-center">
                        
                        <div class="capitalize truncate flex-grow">{{attachment.name}}</div>
                        <div class="flex-none ml-4">{{humanFileSize(attachment.size)}}</div>
                        
                        <div class="flex-none ml-4">
                            <i 
                                class="fas fa-trash text-sm w-8 h-8 flex items-center justify-center mr-1"
                                :title="$t('general.remove')"
                                @click="removeAttachment(attachment)"
                            />
                        </div>
                    </div>
                </div>
                
                <InputUpload
                    :name="'attachment'"
                    :label="$t('general.uploadFile')"
                    :showRequiredWarning="showRequiredWarning"
                    :multiple="true"
                    @input="onAttachmentAdded($event)"
                />
            </section>
                
        </div>
    </form>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true,
        }
    },
    
    data() {
        return {
            showRequiredWarning: false,
            loading: null,
            productEdit: null,
        }
    },
    
    computed: {
        images(){
            if (!this.productEdit.images) {
                return false;
            }
            
            return this.productEdit.images.map( image => {
                return process.env.VUE_APP_FILES_ENDPOINT + '/'+this.accountId+'/' + image.fileId +'.'+ image.extension;
            });
        }
    },
    
    watch:{
        product(oldValue,newValue){
            if (oldValue.productId !== newValue.productId ) {
                this.createProductCopy();
            }
        }
    },
    
    methods: {
        async updateProduct() {
            try {
                this.loading = 'update';
                await this.$store.dispatch('updateProduct', this.productEdit);
                this.$notify({ type: 'success', text: this.$t('products.savedProduct') });
                this.$emit('close');
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: this.$t('products.problemSaving')
                });
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        async deleteProduct() {
            try {
                this.loading = 'delete';
                await this.$store.dispatch('deleteProduct', this.productEdit);
                this.$notify({ type: 'success', text: this.$t('products.deletedProduct') });
                this.$emit('close');
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: this.$t('products.deletedProduct')
                });
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        
        onImageAdded( file ){
            this.productEdit.images.push( file );
            analytics.track( 'productEdit onImageAdded', file);
        },
        
        onAttachmentAdded( file ){
            this.productEdit.attachments.push( file );
            analytics.track( 'productEdit onAttachmentAdded', file);
        },
        
        imageToUrl( image ){
            if (image.imageUrl) {
                return image.imageUrl
            }
            
            return process.env.VUE_APP_FILES_ENDPOINT + '/'+this.accountId+'/' + image.fileId +'.'+ image.extension;
        },
        
        createProductCopy(){
            this.productEdit = JSON.parse(
                JSON.stringify( 
                    Object.assign({
                        images: [],
                        attachments: [],
                        cover: null,
                        isDateProduct: false,
                        ...this.product,
                    })
                )
            );
        },
        
        closeHandler( e ){
            if (e.keyCode === 27) {
                this.$emit('close');
            }
        },
        
        setCover(image){
            this.productEdit.cover = image.fileId;
            analytics.track( 'productEdit setCover', image);
        },
        
        removeImage(image){
            if (
                this.productEdit.cover && 
                this.productEdit.cover == image.fileId ) {
                
                this.productEdit.cover = null;          
            }
            
            this.productEdit.images = this.productEdit.images.filter( i => i != image)
            analytics.track( 'productEdit removeImage', image);
        },
        
        removeAttachment(attachment){
            this.productEdit.attachments = this.productEdit.attachments.filter( a => a != attachment)
            analytics.track( 'productEdit removeAttachment', attachment);
        },
    },
    
    mounted(){
        this.createProductCopy();
        window.addEventListener('keyup', this.closeHandler );
        analytics.track( 'productEdit opened', this.product);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.closeHandler);
    },
    
    
}
</script>

<style lang="scss">
</style>
